import React, { useEffect } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import ProjectRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider, useDispatch } from "react-redux";
import store from "./store";
import { ThemeProvider } from "@material-tailwind/react";
import { refreshAuth } from "./redux/features/login/auth.slice";
import { parseJwt } from "./utils";

function App() {

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider>
          <ToastContainer />
          <ProjectRoutes />
        </ThemeProvider>
      </Provider>
      {/* <Home /> */}
      {
        // <Signup />
        // <Login />
      }
      {/* <StudentLogin /> */}
    </div>
  );
}

export default App;
