import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authSelector } from "../../redux/features/login/auth.slice";
import ProfileDropDown from "../ProfileDropdown/ProfileDropDown";
import { GiHamburgerMenu } from "react-icons/gi";
import MenuItems from "./MenuItems";

const NavBar = () => {
  const menuRef = useRef(null);
  const [active, setActive] = useState(false);

  const { user } = useSelector(authSelector);

  const showMenu = () => {
    setActive(!active);
  };

  const closeOpenMenus = (e) => {
    if (menuRef.current && active && !menuRef.current.contains(e.target)) {
      setActive(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    // <nav className=" overflow-x-hidden navbar-main border-gray-200 px-2 sm:px-4 py-2.5 flex justify-end">
    //   <div className="flex flex-wrap justify-between items-center">
    //     <a href="https://flowbite.com/" className="flex items-center">
    //       {/* <img
    //         src="https://flowbite.com/docs/images/logo.svg"
    //         className="mr-3 h-6 sm:h-9"
    //         alt="Flowbite Logo"
    //       />
    //       <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
    //         Flowbite
    //       </span> */}
    //     </a>
    //     <button
    //       onClick={handleDropDownMenu}
    //       data-collapse-toggle="navbar-default"
    //       type="button"
    //       className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    //       aria-controls="navbar-default"
    //       aria-expanded="false"
    //     >
    //       <span className="sr-only">Open main menu</span>
    //       <svg
    //         className="w-6 h-6"
    //         aria-hidden="true"
    //         fill="currentColor"
    //         viewBox="0 0 20 20"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           fillRule="evenodd"
    //           d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
    //           clipRule="evenodd"
    //         ></path>
    //       </svg>
    //     </button>
    //     <div
    //       className={`${show ? "h-auto m-10 " : ""} w-full md:block md:w-auto`}
    //       id="navbar-default "
    //     >
    //       <ul className="items-center flex flex-col p-4 mt-4 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0   dark:border-gray-700">
    //         <li>
    //           <Link
    //             to="/"
    //             className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:p-0 dark:text-white"
    //             aria-current="page"
    //           >
    //             Home
    //           </Link>
    //         </li>
    //         {user ? (
    //           <li>
    //             <Link
    //               to="/dashboard"
    //               className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:p-0 dark:text-white"
    //               aria-current="page"
    //             >
    //               Dashboard
    //             </Link>
    //           </li>
    //         ) : null}
    //         <li>
    //           <a
    //             href="#"
    //             className="block py-2 pr-4 pl-3  text-white  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
    //           >
    //             Courses
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#"
    //             className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
    //           >
    //             About Us
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#"
    //             className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
    //           >
    //             Find Teacher
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="#"
    //             className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
    //           >
    //             Contact
    //           </a>
    //         </li>
    //         <>
    //           {!user ? (
    //             <>
    //               <li>
    //                 <Link
    //                   to="/auth/login"
    //                   className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    //                 >
    //                   Sign in
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link
    //                   to="/auth/register"
    //                   className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    //                 >
    //                   Register
    //                 </Link>
    //               </li>
    //             </>
    //           ) : null}
    //         </>
    //         <>
    //           {user ? (
    //             <li>
    //               <ProfileDropDown user={user} />
    //             </li>
    //           ) : null}
    //         </>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
    <div
      ref={menuRef}
      className="h-20 navbar-main w-full text-white flex justify-between items-center p-4 z-50"
    >
      <div className="text-2xl font-bold text-center uppercase font-serif">
        <img />
      </div>
      <nav>
        <div className="lg:hidden absolute right-6 top-8 cursor-pointer">
          <GiHamburgerMenu onClick={showMenu} size={24} />
        </div>

        <ul className="hidden lg:flex gap-8 p-6">
          <li>
            <Link
              to="/"
              className="font-medium text-white  rounded md:bg-transparent md:p-0 dark:text-white hover:text-gray-300 hover:underline "
            >
              Home
            </Link>
          </li>
          {user ? (
            <li>
              <Link
                to="/dashboard"
                className="font-medium text-white  rounded md:bg-transparent md:p-0 dark:text-white hover:text-gray-300 hover:underline "
              >
                Dashboard
              </Link>
            </li>
          ) : null}
          <li>
            <Link
              to="/"
              className="font-medium text-white  rounded md:bg-transparent md:p-0 dark:text-white hover:text-gray-300 hover:underline "
            >
              Find Teacher
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className="font-medium text-white  rounded md:bg-transparent md:p-0 dark:text-white hover:text-gray-300 hover:underline "
            >
              Courses
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className="font-medium text-white  rounded md:bg-transparent md:p-0 dark:text-white hover:text-gray-300 hover:underline "
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/"
              className="font-medium text-white  rounded md:bg-transparent md:p-0 dark:text-white hover:text-gray-300 hover:underline "
            >
              Contact
            </Link>
          </li>
          {!user ? (
            <>
              <li>
                <Link
                  to="/auth/login"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Sign in
                </Link>
              </li>
              <li>
                <Link
                  to="/auth/register"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Register
                </Link>
              </li>
            </>
          ) : null}
          {user ? (
            <li className="-mt-3">
              <ProfileDropDown user={user} />
            </li>
          ) : null}
        </ul>

        <MenuItems showMenu={showMenu} active={active} />
      </nav>
    </div>
  );
};

export default NavBar;
