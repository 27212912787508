import React, { Suspense, lazy } from "react";
import { Loader } from "../components/Loader";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export const Login = Loadable(lazy(() => import("../pages/auth/login/Login")));

export const Signup = Loadable(
  lazy(() => import("../pages/auth/Signup/Signup"))
);

export const StudentProfile = Loadable(
  lazy(() =>
    import("../pages/student").then((module) => ({
      default: module.StudentProfile,
    }))
  )
);

export const StudentCourseEnrollments = Loadable(
  lazy(() =>
    import("../pages/student").then((module) => ({
      default: module.StudentCourseEnrollments,
    }))
  )
);
export const MyCourseDetails = Loadable(
  lazy(() => import("../pages/MyCourseDetails"))
);

export const Home = Loadable(lazy(() => import("../pages/Home")));

export const Courses = Loadable(lazy(() => import("../pages/Courses")));
export const Admin = Loadable(lazy(() => import("../pages/admin")));

export const TeacherProfile = Loadable(
  lazy(() =>
    import("../pages/teacher").then((module) => ({
      default: module.TeacherProfile,
    }))
  )
);

export const TeacherDetails = Loadable(
  lazy(() =>
    import("../pages/teacher").then((module) => ({
      default: module.TeacherDetails,
    }))
  )
);

export const CourseDetails = Loadable(
  lazy(() =>
    import("../pages/CourseProfile").then((module) => ({
      default: module.CourseDetails,
    }))
  )
);

export const CreateCourseProfile = Loadable(
  lazy(() =>
    import("../pages/CourseProfile").then((module) => ({
      default: module.CreateCourseProfile,
    }))
  )
);

export const UpdateCourseProfile = Loadable(
  lazy(() =>
    import("../pages/CourseProfile").then((module) => ({
      default: module.UpdateCourseProfile,
    }))
  )
);
export const AllCourseProfiles = Loadable(
  lazy(() =>
    import("../pages/CourseProfile").then((module) => ({
      default: module.AllCourseProfiles,
    }))
  )
);
// export const MyCoursesDetails = Loadable(
//   lazy(() => import("../pages/MyCoursesDetails"))
// );
