import React from "react";

const Copyright = () => {
  return (
    <footer className=" bg-blue-800 text-center text-white ">
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2021 Copyright:
        <a className="text-white" href="https://tailwind-elements.com/">
          Tailwind Elements
        </a>
      </div>
    </footer>
  );
};

export default Copyright;
