export const CLIENT_ID =
  "559665393661-i14ueink1e0p0g9ccdsgdisc6mc0r3rd.apps.googleusercontent.com";
export const TEACHER_ROLE = 1;
export const STUDENT_ROLE = 2;
export const baseURL = "http://167.71.76.48:7250/api/v1/";
export const hostURL = "http://167.71.76.48:7250";
export const createTeacher = "/teachers/create";
export const createStudent = "/students/create";
export const createParent = "/parents/create";
export const loginStudent = "/authentication/authStudent";
export const loginTeacher = "/authentication/authTeacher";
export const loginPasent = "/authentication/authParent";
export const createCourse = "/courses/create";
export const updateTeacherProfile = "/teachers/update";
export const updateStudentProfile = "/students/update";
export const getAllCourses = "/courses/getAllCourses";
export const courseProfileById = "/courses/getAllCourses";
export const teacherProfileById = "/teachers/getTeacherById";
export const studentProfileById = "/students/getStudentsById";
export const createCourseProfile = "/teacherCourseProfile/create";
export const teacherCourseProfiles =
  "/teacherCourseProfile/getAllTeacherCourseProfile";
export const teachers = "/teachers/getAllTeachers";
export const loginAdmin = "/authentication/authSuperAdmin";
export const getStatuses = "/status/getAllStatus";

export const changeTeacherStatus = "/teachers/updateTeacherStatus";
export const changeTeacherCourseStatus =
  "/teacherCourseProfile/updateTeacherCourseProfileStatus";
export const courseDetails =
  "/teacherCourseProfile/getTeacherCourseProfileById";
export const courseEnroll = "/courseEnrollment/create";
export const fetchTeacherById = "/teachers/getTeacherById";
export const fetchStudentCourseEnrollments =
  "/courseEnrollment/getCourseEnrollmentByStudent";
export const fetchTeacherCoursesById =
  "/teacherCourseProfile/getAllTeacherCourseProfileByTeacherId";
export const fetchTeacherPendingCoursesById =
  "/teacherCourseProfile/getAllUnApprovedTeacherCourseProfile";
export const fetchTeacherApprovedCoursesById =
  "/teacherCourseProfile/getAllApprovedTeacherCourseProfile";
