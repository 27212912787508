import React from "react";
import Copyright from "../components/Copyright";
import Sidebar from "../components/Sidebar";
import { Footer } from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="flex">
        <div>
          <Sidebar />
        </div>
        <div className="w-full h-screen">{children}</div>
      </div>
      <Copyright />
    </div>
  );
};

export default Layout;
