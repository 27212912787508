import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/features/login/auth.slice";
import { logout } from "../../redux/features/login/auth.slice";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";

const MenuItems = ({ showMenu, active }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);

  return (
    <>
      <ul
        className={
          active
            ? "lg:hidden flex-col flex items-center fixed z-20 inset-0 left-2/4 gap-5 my-5  navbar-main"
            : "hidden"
        }
      >
        <IoCloseOutline
          onClick={showMenu}
          size={24}
          className="cursor-pointer"
        />
        <li>
          <Link
            to="/"
            className="font-medium text-white text-xs md:text-base sm:text-xs  rounded md:bg-transparent lg:p-0 dark:text-white hover:text-gray-300 hover:underline cursor-pointer "
          >
            Home
          </Link>
        </li>
        {user ? (
          <li>
            <Link
              to="/dashboard"
              className="font-medium text-white text-xs md:text-base sm:text-xs rounded lg:bg-transparent lg:p-0 dark:text-white hover:text-gray-300 hover:underline cursor-pointer "
            >
              Dashboard
            </Link>
          </li>
        ) : null}

        <li>
          <Link
            to="/"
            className="font-medium text-white text-xs md:text-base sm:text-xs  rounded lg:bg-transparent lg:p-0 dark:text-white hover:text-gray-300 hover:underline cursor-pointer"
          >
            Find Teacher
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className="font-medium text-white text-xs md:text-base sm:text-xs rounded lg:bg-transparent lg:p-0 dark:text-white hover:text-gray-300 hover:underline cursor-pointer"
          >
            Courses
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className="font-medium text-white  text-xs md:text-base sm:text-xs rounded lg:bg-transparent lg:p-0 dark:text-white hover:text-gray-300 hover:underline cursor-pointer"
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className="font-medium text-white text-xs md:text-base sm:text-xs rounded lg:bg-transparent lg:p-0 dark:text-white hover:text-gray-300 hover:underline cursor-pointer"
          >
            Contact
          </Link>
        </li>
        {!user ? (
          <>
            <li className="my-5">
              <Link
                to="/auth/login"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "
              >
                Sign in
              </Link>
            </li>
            <li>
              <Link
                to="/auth/register"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  lg:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Register
              </Link>
            </li>
          </>
        ) : (
          <li
            onClick={() => {
              dispatch(logout());
            }}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  lg:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Logout
          </li>
        )}
      </ul>
    </>
  );
};

export default MenuItems;
