import { toast } from "react-toastify";

export const notify = (type, msg) => {
  toast[type](msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export function formatAMPM(time) {
  var hours = time?.split(":")[0];
  var minutes = time?.split(":")[1];
  var seconds = "00";
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? minutes : minutes;

  var strTime =
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    minutes +
    ":" +
    seconds +
    " " +
    ampm;
  return strTime;
}

export const parseJwt = (token) => {
  var base64Url = token?.split(".")[1];
  var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
  if (base64) {
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        ?.split("")
        ?.map(function (c) {
          return "%" + ("00" + c?.charCodeAt(0)?.toString(16))?.slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return null;
  }
};
