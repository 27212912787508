import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInStudent } from "../../../apis";
import { parseJwt } from "../../../utils";
import { STUDENT_AUTH } from "../../configs";

export const loginStudent = createAsyncThunk(
  STUDENT_AUTH,
  async (data, thunkAPI) => {
    try {
      const res = await signInStudent(data);
      if (res.status === 200) {
        localStorage.setItem("auth-token", res.data);
        return parseJwt(res.data);
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
