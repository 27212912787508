import { useRoutes } from "react-router-dom";
import Layout from "../layout/layout";
import StudentLayout from "../layout/StudentLayout";
import TeacherLayout from "../layout/TeacherLayout";
import {
  Home,
  Login,
  Signup,
  StudentProfile,
  StudentCourseEnrollments,
  AllCourseProfiles,
  TeacherProfile,
  CourseDetails,
  CreateCourseProfile,
  TeacherDetails,
  MyCourseDetails,
} from "./elements";

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <Signup />,
        },
      ],
    },
    {
      path: "/",
      element: <Home />,
      children: [],
    },

    {
      path: "course-detail/:id",
      element: <CourseDetails />,
    },
    {
      path: "courses",
      element: <TeacherLayout />,
      children: [
        {
          path: "create",
          element: <CreateCourseProfile />,
        },
      ],
    },
    {
      path: "dashboard",
      element: <Layout />,
      children: [],
    },
    {
      path: "teacher-detail/:id",
      element: <TeacherDetails />,
    },
    {
      path: "teacher",
      element: <TeacherLayout />,
      children: [
        {
          path: "my-courses",
          element: <AllCourseProfiles />,
        },
        {
          path: "courses/create",
          element: <CreateCourseProfile />,
        },
        {
          path: "profile/:id",
          element: <TeacherProfile />,
        },
      ],
    },
    {
      path: "student",
      element: <StudentLayout />,
      children: [
        {
          path: "profile/:id",
          element: <StudentProfile />,
        },
        {
          path: "course-details/:id",
          element: <MyCourseDetails />,
        },
        {
          path: "my-courses",
          element: <StudentCourseEnrollments />,
        },
        {
          path: "course-details/:id",
          element: <MyCourseDetails />,
        },
      ],
    },
  ]);
}
