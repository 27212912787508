import { createSlice } from "@reduxjs/toolkit";
import { fetchAllStatuses } from "../../asyncActions/common/statuses";

export const statusesSlice = createSlice({
  name: "statuses",
  initialState: {
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    // Reducer comes here
  },
  extraReducers: {
    [fetchAllStatuses.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [fetchAllStatuses.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchAllStatuses.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.error.message;
    },
  },
});
export const statusesSelector = (state) => state.statuses;
