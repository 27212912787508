import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginStudent } from "../../asyncActions/student/auth";
import { loginTeacher } from "../../asyncActions/teacher/auth";

export const authSlice = createSlice(
  {
  name: "auth",
  initialState: {
    user: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    refreshAuth(state, {payload}) {
      state.user = payload;
    },
    logout(state) {
      localStorage.removeItem("auth-token");
      state.user = null;
      state.isSuccess = false;
      window.location.href = "/";
    },
  },
  extraReducers: {
    [loginTeacher.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.user = payload;
    },
    [loginTeacher.pending]: (state) => {
      state.isFetching = true;
    },
    [loginTeacher.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginStudent.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.user = payload;
    },
    [loginStudent.pending]: (state) => {
      state.isFetching = true;
    },
    [loginStudent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.error.message;
    },
  },
});
export const authSelector = (state) => state.auth;
export const { refreshAuth, logout } = authSlice.actions;
