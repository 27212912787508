import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/login/auth.slice";
import "./style.css";
import { hostURL } from "../../constants";
import placeHolder from "../../assets/f3.png";

const ProfileDropDown = ({ user }) => {
  const dispatch = useDispatch();
  // console.log(user?.image);

  const dropdownRef = useRef(null);
  const [show, setShow] = useState();
  const handleClick = () => {
    setShow(!show);
  };

  const closeOpenMenus = (e) => {
    if (
      dropdownRef.current &&
      show &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShow(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className={`  h-10 w-36 `} onClick={handleClick} ref={dropdownRef}>
      <div className="flex  items-center flex-wrap cursor-pointer mt-1">
        <span>
          <img
            className="w-10 h-10 rounded-3xl"
            src={user?.image ? hostURL + user?.image : placeHolder}
            alt="profile-img"
          />
        </span>
        <span className="ml-2">{user?.firstName}</span>
      </div>
      {show ? (
        <div
          className={`${
            show
              ? "absolute w-36 cursor-pointer h-12 flex justify-center items-center z-50 mt-3 bg-white rounded-md shadow-md text-sm text-center text-black hover:bg-gray-200"
              : "hidden"
          }   `}
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </div>
      ) : null}
    </div>
  );
};

export default ProfileDropDown;
