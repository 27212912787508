import axios from "axios";
import {
  baseURL,
  createParent,
  createStudent,
  createTeacher,
  loginPasent,
  loginStudent,
  loginTeacher,
  createCourse,
  getAllCourses,
  updateTeacherProfile,
  teacherProfileById,
  studentProfileById,
  createCourseProfile,
  teacherCourseProfiles,
  updateStudentProfile,
  teachers,
  loginAdmin,
  getStatuses,
  changeTeacherStatus,
  changeTeacherCourseStatus,
  courseDetails,
  courseEnroll,
  fetchTeacherById,
  fetchTeacherCoursesById,
  fetchStudentCourseEnrollments,
  fetchTeacherPendingCoursesById,
  fetchTeacherApprovedCoursesById,
} from "../constants";

const API = axios.create({
  baseURL,
  validateStatus: (status) => status === 200 || status === 201,
});

export const signUpTeacherWithGoogle = async (body) => {
  try {
    const res = await API.post(createTeacher, body);
    return res;
  } catch (err) {
    return err;
  }
};
export const LoginTeacherWithGoogle = async (body) => {
  try {
    const res = await API.post(loginTeacher, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const signUpStudent = async (body) => {
  try {
    const res = await API.post(createStudent, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const signInStudent = async (body) => {
  const res = await API.post(loginStudent, body);
  return res;
};
export const signInAdmin = async (body) => {
  const res = await API.post(loginAdmin, body);
  return res;
};
export const signUpParent = async (body) => {
  try {
    const res = await API.post(createParent, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const signInparent = async (body) => {
  try {
    const res = await API.post(loginPasent, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const createNewCourse = async (body) => {
  try {
    const res = await API.post(createCourse, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const createNewCourseProfile = async (body) => {
  try {
    const res = await API.post(createCourseProfile, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getListAllCourses = async () => {
  try {
    const res = await API.get(getAllCourses);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getAllTeacherCourseProfiles = async () => {
  try {
    const res = await API.get(teacherCourseProfiles);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getAllTeachers = async () => {
  try {
    const res = await API.get(teachers);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};

export const saveTeacherProfile = async (id, body) => {
  try {
    const res = await API.put(`${updateTeacherProfile}/${id}`, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const saveStudentProfile = async (id, body) => {
  try {
    const res = await API.put(`${updateStudentProfile}/${id}`, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getTeacherProfileById = async (id) => {
  try {
    const res = await API.get(`${teacherProfileById}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getStudentProfileById = async (id) => {
  try {
    const res = await API.get(`${studentProfileById}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getAllStatuses = async () => {
  try {
    const res = await API.get(getStatuses);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const updateTeacherStatus = async (body, id) => {
  try {
    const res = await API.put(`${changeTeacherStatus}/${id}`, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const updateTeacherCourseStatus = async (body, id) => {
  try {
    const res = await API.put(`${changeTeacherCourseStatus}/${id}`, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getCourseDetails = async (id) => {
  try {
    const res = await API.get(`${courseDetails}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const createCourseEnroll = async (body) => {
  try {
    const res = await API.post(courseEnroll, body);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getTeacherById = async (id) => {
  try {
    const res = await API.get(`${fetchTeacherById}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};

export const getTeacherCoursesById = async (id) => {
  try {
    const res = await API.get(`${fetchTeacherCoursesById}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getTeacherPendingCoursesById = async (id) => {
  try {
    const res = await API.get(`${fetchTeacherPendingCoursesById}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getTeacherApprovedCoursesById = async (id) => {
  try {
    const res = await API.get(`${fetchTeacherApprovedCoursesById}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
export const getStudentCourseEnrollmentsById = async (id) => {
  try {
    const res = await API.get(`${fetchStudentCourseEnrollments}/${id}`);
    return res;
  } catch (err) {
    console.log(err, "error");
  }
};
