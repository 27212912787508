import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInAdmin } from "../../../apis";
import { parseJwt } from "../../../utils";
import { ADMIN_AUTH } from "../../configs";

export const loginAdmin = createAsyncThunk(
  ADMIN_AUTH,
  async (data, thunkAPI) => {
    try {
      const res = await signInAdmin(data);
      if (res.status === 200) {
        localStorage.setItem("auth-token", res.data);
        return parseJwt(res.data);
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
