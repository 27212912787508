import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginAdmin } from "../../asyncActions/admin/auth";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    user: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    // Reducer comes here
  },
  extraReducers: {
    [loginAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.user = payload;
    },
    [loginAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    [loginAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.error.message;
    },
  },
});
export const adminSelector = (state) => state.admin;
