import { configureStore } from "@reduxjs/toolkit";
import { adminSlice } from "./redux/features/admin/auth.slice";
import { statusesSlice } from "./redux/features/common/statuses.slice";
import { authSlice } from "./redux/features/login/auth.slice";
// import { studentSlice } from "./redux/features/student/auth.slice";
// import { teacherSlice } from "./redux/features/teacher/auth.slice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    admin: adminSlice.reducer,
    statuses: statusesSlice.reducer,
  },
});
