import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsPinterest } from "react-icons/bs";
import { AiOutlineTwitter } from "react-icons/ai";

export const Footer = () => {
  return (
    <div className="footer-wrapper pt-10">
      <div className="footer container mx-auto pt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 ">
          <ul>
            <li className="flex mb-5 items-center">
              <IoLocationSharp />
              &nbsp;
              <span>1500 Treat Ave, Suite 200 San Francisco, CA 94110</span>
            </li>
            <li className="flex mb-5 items-center">
              <MdOutlineMail />
              &nbsp;
              <span>vctung@outlook.com</span>
            </li>
            <li className="flex mb-5 items-center">
              <BsTelephoneFill />
              &nbsp;
              <span>1-800-800-2299 (support)</span>
            </li>
          </ul>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div>
              <h2 className="footer-heading">Community</h2>
              <ul>
                <li className="mb-2 mt-2">Learners</li>
                <li className="mb-2 ">Partners</li>
                <li className="mb-2 ">Developers</li>
                <li className="mb-2 ">Beta Testers</li>
                <li className="mb-2 ">Translators</li>
                <li className="mb-2 ">Blog</li>
                <li className="mb-2 ">Tech Blog</li>
                <li className="mb-2 ">Teaching Center</li>
              </ul>
            </div>
            <div>
              <h2 className="footer-heading">Informations</h2>
              <ul>
                <li className="mb-2 mt-2">About</li>
                <li className="mb-2">Pricing</li>
                <li className="mb-2">Blog</li>
                <li className="mb-2">Careers</li>
                <li className="mb-2">Contact</li>
              </ul>
            </div>
            <div>
              <h2 className="footer-heading">More</h2>
              <ul>
                <li className="mb-2 mt-2">Press</li>
                <li className="mb-2">Investors</li>
                <li className="mb-2">Terms</li>
                <li className="mb-2">Privacy</li>
                <li className="mb-2">Help</li>
                <li className="mb-2">Blog</li>
                <li className="mb-2">Accessibility</li>
                <li className="mb-2">Contact</li>
              </ul>
            </div>
          </div>{" "}
        </div>{" "}
        <div className="footer-bottom p-8 mt-10 flex sm:flex-row sm:justify-between ">
          <div className="copyright">
            <span>copyright &#169; 2021</span>
          </div>
          <div className="social-icons  flex ">
            <span className="m-2">
              <FaFacebookF />
            </span>
            <span className="m-2">
              <AiOutlineInstagram />
            </span>
            <span className="m-2">
              <BsPinterest />
            </span>
            <span className="m-2">
              <AiOutlineTwitter />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
